import { Container, Content, Title, Image } from './styles';
import Cat from '../../assets/cats.jpg'

const Home = () => {
  return (
    <Container>
      <Content>
      <Title>Era uma vez dezenove gatinhos...</Title>
      <Image  alt='te amo besta sz' src={Cat}></Image>
      </Content>
    </Container>

  );
}

export default Home;