import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  gap: 50px;
`;

export const Title = styled.h1`
  font-size: 50px;
  height: 20%;
`

export const Image = styled.img`
  width: auto;
  height: 80%;
`